/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import { SignupEvent } from '@experiences/telemetry';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import type { ITelemetryItem } from '@microsoft/applicationinsights-web';
import {
    ApplicationInsights,
    SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';

import getEnvironment from '../util/EnvironmentUtil';

const emailPiiRegex = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+/g;
const emailPiiMask = '<PII_EMAIL>';

function loadInstrumentationKeyFromEnv() {
    const environment = getEnvironment();
    if (environment === 'local') {
        return require('../envConfigs/local.json')['APPLICATION_INSIGHTS_INSTRUMENTATION_KEY'];
    } else if (environment === 'nst') {
        return require('../envConfigs/nst.json')['APPLICATION_INSIGHTS_INSTRUMENTATION_KEY'];
    } else if (environment === 'alp') {
        return require('../envConfigs/alp.json')['APPLICATION_INSIGHTS_INSTRUMENTATION_KEY'];
    } else if (environment === 'stg') {
        return require('../envConfigs/stg.json')['APPLICATION_INSIGHTS_INSTRUMENTATION_KEY'];
    }
    return require('../envConfigs/prd.json')['APPLICATION_INSIGHTS_INSTRUMENTATION_KEY'];
}
const instrumentationKey = loadInstrumentationKeyFromEnv();
const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey,
        // Known issue: https://github.com/microsoft/applicationinsights-react-js/issues/32
        extensions: [ reactPlugin as any ],
        enableAutoRouteTracking: true,
        extensionConfig: { [reactPlugin.identifier]: { history: browserHistory } },
    },
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
    try {
        item.tags = item.tags ?? [];
        item.tags['ai.cloud.role'] = 'auth0';
    } catch (e) {
        console.error(e);
        return false;
    }

    return;
});

appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
    try {
        const itemJson = JSON.stringify(item);
        const isEmailPiiFound = itemJson.search(emailPiiRegex) > -1;
        if (isEmailPiiFound) {
            const sanitizedItemString = itemJson.replace(emailPiiRegex, emailPiiMask);
            const itemObj = JSON.parse(sanitizedItemString);
            Object.assign(item, itemObj);
        }
    } catch (e) {
        console.error(e);
        return false;
    }

    return;
});

// @ts-ignore
appInsights.context.application = {
    build: getEnvironment(),
    ver: getEnvironment(),
};

export function logClick(from: string, internal: boolean, link: string) {
    const _ga = Cookies.get('_ga');
    appInsights.trackTrace(
        {
            message: 'Click',
            severityLevel: SeverityLevel.Information,
        },
        {
            GAId: _ga,
            from,
            internal,
            link,
        },
    );
}

export function logSignupSubmit(subscriptionPlan: string | null) {
    const _ga = Cookies.get('_ga');
    const signupType = 'basic';
    appInsights.trackEvent(
        { name: SignupEvent.Submit },
        {
            GAId: _ga,
            subscriptionPlan,
            signupType,
        },
    );
}

export function logAccessPage(name: string, source: string) {
    const _ga = Cookies.get('_ga');
    const eventDetails = {
        Code: '[IN-AL-00]',
        IsError: false,
    };
    appInsights.trackEvent({
        name: 'AccessPage.AccessLandingPage',
        properties: {
            Message: `${eventDetails.Code} - ${name} landing page was accessed from ${source} source`,
            Code: eventDetails.Code,
            IsError: eventDetails.IsError,
        },
    }, {
        GAId: _ga,
        Source: source,
    });
}

export default appInsights;
