import { maskPii } from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import type {
    Auth0Error,
    CrossOriginLoginOptions,
} from 'auth0-js';
import auth0 from 'auth0-js';
import Cookies from 'js-cookie';

import { sendAuthSignupRequest } from '../services/AuthUserService';
import telemetryProvider from '../telemetry';
import {
    getLastUsedLoginEmail,
    getLastUsedLoginMethod,
    setCurrentLoginMethod,
} from './CookieUtil';
import getCountryCode from './CountryUtil';
import Providers from './interfaces/provider';
import type {
    ISignupDto,
    ISimpleSignupDto,
} from './interfaces/signup';
import { getParams } from './RouteUtil';
import Telemetry from './TelemetryUtil';

export default class Auth {
    private static instance: Auth;
    private _auth0: auth0.WebAuth;
    private _telemetry: Telemetry;

    private constructor() {
        // TODO: figure out good local setup for this
        const config = JSON.parse(decodeURIComponent(escape(window.atob((window as any).CONFIG))));
        const authParams = Object.assign(
            {
                // additional configuration needed for use of custom domains
                overrides: {
                    __tenant: config.auth0Tenant,
                    __token_issuer: config.auth0Domain,
                },
                domain: config.auth0Domain,
                clientID: config.clientID,
                redirectUri: config.callbackURL,
                responseType: 'token id_token',
            },
            config.internalOptions,
        );

        this._telemetry = new Telemetry();
        this._auth0 = new auth0.WebAuth(authParams);
    }

    static getInstance(): Auth {
        if (!Auth.instance) {
            try {
                Auth.instance = new Auth();
            } catch (err) {
                const _ga = Cookies.get('_ga');
                telemetryProvider.trackTrace(
                    {
                        message: `Error creating Auth object: ${JSON.stringify(err)}`,
                        severityLevel: SeverityLevel.Error,
                    },
                    {
                        GAId: _ga,
                        method: 'create-auth',
                    },
                );
            }
        }

        return Auth.instance;
    }

    basicSignupDB = (
        {
            organizationName,
            firstName,
            lastName,
            jobTitle,
            email,
            password,
            country,
            state,
            marketingConditionsAccepted,
            language,
            userSignupSubscriptionPlan,
            platformName,
            companyCode,
            isUserInvite,
        }: ISignupDto,
        enableMarketingFields: boolean,
        errCallback: auth0.Auth0Callback<void>,
    ) => {
        this._telemetry.trackEventAuthorizationMethod(true, 'basic');

        const marketingAdditionalFields = enableMarketingFields
            ? JSON.stringify({
                jobTitle,
                state: companyCode?.substr(0, 3) === 'B2B' && !isUserInvite ? state : undefined,
            })
            : undefined;

        const country_code = getCountryCode(country);

        const user_metadata = {
            firstName,
            lastName,
            country,
            country_code,
            marketingAdditionalFields,
            marketingConditionAccepted: marketingConditionsAccepted ? 'true' : 'false',
            user_signup_language: language,
            userSignupSubscriptionPlan,
            platform_name: platformName,
        };

        if (companyCode?.substr(0, 3) === 'B2B') {
            const b2b_extra_params = isUserInvite
                ? { termsAndConditionsAccepted: 'true' }
                : {
                    termsAndConditionsAccepted: 'true',
                    country,
                    companyName: organizationName,
                };
            Object.assign(user_metadata, b2b_extra_params);
        } else {
            const b2c_extra_params = { termsAndConditionsB2CAccepted: 'true' };
            Object.assign(user_metadata, b2c_extra_params);
        }

        setCurrentLoginMethod(Providers.Email);

        // user_metadata prop doesn't exist on signupAndLogin, but this still works
        this._auth0.redirect.signupAndLogin(
            {
                connection: 'Username-Password-Authentication',
                email,
                password,
                userMetadata: user_metadata,
            },
            (err: Auth0Error | null) => {
                this._telemetry.trackTraceAuthorizationMethod(
                    true,
                    'basic',
                    SeverityLevel.Error,
                    `Basic signup failed with error: ${JSON.stringify(maskPii(err))}`,
                );
                errCallback(err);
            },
        );
    };

    simpleSignupDB = ({
        email, password, isBusinessEmail, language, client,
    }: ISimpleSignupDto,
    signupViaPortalBEEnabled: boolean,
    errCallback: auth0.Auth0Callback<void>) => {
        this._telemetry.trackEventAuthorizationMethod(true, 'basic');

        setCurrentLoginMethod(Providers.Email);

        if (signupViaPortalBEEnabled) {
            sendAuthSignupRequest({
                email,
                password,
            }, client ?? '')
                .then(() => {
                    const options: CrossOriginLoginOptions = {
                        username: email,
                        password,
                        realm: 'Username-Password-Authentication',
                    };

                    return this._auth0.login(options, errCallback);
                })
                .catch(error => {
                    this._telemetry.trackTraceAuthorizationMethod(
                        true,
                        'basic',
                        SeverityLevel.Error,
                        `Basic signup failed with error: ${JSON.stringify(maskPii(error))}`,
                    );

                    errCallback(error.response?.data ?? {});
                });
        } else {
            // user_metadata prop doesn't exist on signupAndLogin, but this still works
            this._auth0.redirect.signupAndLogin(
                {
                    connection: 'Username-Password-Authentication',
                    email,
                    password,
                    userMetadata: {
                        business_account: String(isBusinessEmail),
                        user_signup_language: language,
                    },
                },
                (err: Auth0Error | null) => {
                    this._telemetry.trackTraceAuthorizationMethod(
                        true,
                        'basic',
                        SeverityLevel.Error,
                        `Basic signup failed with error: ${JSON.stringify(maskPii(err))}`,
                    );
                    errCallback(err);
                },
            );
        }
    };

    basicLogin(username: string, password: string, errCallback: auth0.Auth0Callback<void>) {
        this._telemetry.trackEventAuthorizationMethod(false, 'basic');
        setCurrentLoginMethod(Providers.Email);

        this._auth0.login(
            {
                realm: 'Username-Password-Authentication',
                username,
                password,
            },
            (err: Auth0Error | null) => {
                this._telemetry.trackTraceAuthorizationMethod(
                    true,
                    'basic',
                    SeverityLevel.Error,
                    `Basic login failed with error: ${JSON.stringify(maskPii(err))}`,
                );
                errCallback(err);
            },
        );
    }

    authorizeWithGoogle(signup: boolean, bypassLoginHint: boolean) {
        this._telemetry.trackEventAuthorizationMethod(signup, 'social: google');

        const login_hint = this.getLoginHint(Providers.Google, signup, bypassLoginHint);

        this._auth0.authorize({
            connection: 'google-oauth2',
            login_hint,
        });

        setCurrentLoginMethod(Providers.Google);
    }

    authorizeWithMicrosoft(signup: boolean, bypassLoginHint: boolean) {
        this._telemetry.trackEventAuthorizationMethod(signup, 'social: microsoft');

        const login_hint = this.getLoginHint(Providers.Microsoft, signup, bypassLoginHint);

        this._auth0.authorize({
            connection: 'UiPath-AADV2',
            login_hint,
        });

        setCurrentLoginMethod(Providers.Microsoft);
    }

    authorizeWithLinkedin(signup: boolean, bypassLoginHint: boolean) {
        this._telemetry.trackEventAuthorizationMethod(signup, 'social: linkedin');

        const login_hint = this.getLoginHint(Providers.LinkedIn, signup, bypassLoginHint);

        this._auth0.authorize({
            connection: 'linkedin',
            login_hint,
        });

        setCurrentLoginMethod(Providers.LinkedIn);
    }

    changePassword(email: string, callback: auth0.Auth0Callback<any>) {
        this._auth0.changePassword(
            {
                connection: 'Username-Password-Authentication',
                email,
            },
            callback,
        );
    }

    getLoginHint(provider: string, signup: boolean, bypassLoginHint: boolean) {
        const params = getParams();

        if (params.login_hint) {
            return params.login_hint;
        }

        const method = getLastUsedLoginMethod();

        if (signup || bypassLoginHint || method !== provider) {
            return undefined;
        }

        return getLastUsedLoginEmail() || undefined;
    }
}
